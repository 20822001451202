<template>
  <div id="GreenValleyRanch">
    <WhereWeAreMap :mapData="mapData" />
  </div>
</template>
  
  <script>
import WhereWeAreMap from "@/components/global/WhereWeAreMap";

export default {
  name: "GreenValleyRanch",
  components: {
    WhereWeAreMap,
  },
  data() {
    return {
      mapData: [
        {
          mapBg: require("@/assets/maps/bg-green-valley.jpg"),
          mapLine: "GreenValleyRanchPath",
          venueBg: require("@/assets/maps/green-valley.png"),
          venue: "Green Valley Ranch",
          distance: "9.7",
          singleLineVenue: true,
          threeLineVenue: false,
        },
      ],
    };
  },
};
</script>
  
  <style lang="scss scoped">
</style>